import React from "react";
import { Col, Row } from "react-bootstrap";
import Reactjs from "../../Assets/tech/reactjs.png"
import Javascript from "../../Assets/tech/javascript.png"
import CSS from "../../Assets/tech/css.png"
import Figma from "../../Assets/tech/figma.png"
import Git from "../../Assets/tech/git.png"
import HTML from "../../Assets/tech/html.png"
import MongoDB from "../../Assets/tech/mongodb.png"
import Node from "../../Assets/tech/nodejs.png"
import Redux from "../../Assets/tech/redux.png"
import Tailwind from "../../Assets/tech/tailwind.png"
import Three from "../../Assets/tech/threejs.svg"
import Typescript from "../../Assets/tech/typescript.png"



function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src={Reactjs} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Javascript} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={HTML} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={CSS} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Git} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Figma} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={MongoDB} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Node} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Three} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Redux} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Typescript} style={{width:'100px'}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={Tailwind} style={{width:'100px'}}/>
      </Col>
    
    </Row>
  );
}

export default Techstack;
